import axios from 'axios'

// Login API Call
const authLoginPost = async (userData: any): Promise<ApiResponse> => {
  try {
    const response: ApiResponse = (await axios.post("login", userData)).data;

    // If login is successful, save login details and set token in axios headers
    if (response?.status && !(response?.data?.otp)) {
      localStorage.setItem("loginDetails", JSON.stringify(response?.data));
    }
    return response;

  } catch (error: any) {
    throw error;
  }
};
// sigup verify API call
const authSignUp = async (userData: any): Promise<ApiResponse> => {
  try {
    const response: ApiResponse = (await axios.post("signUp", userData?.userData)).data;
    
    if(response?.data?.is_scac_exist){
      userData?.setShowScacExist({show:true, admin_detail:response?.data?.admin_detail})
    }
    if (response?.status && !(response?.data?.otp)) {
      localStorage.setItem("loginDetails", JSON.stringify(response?.data));
    }
    return response;
  } catch (error: any) {
    throw error;
  }
};

const transportProviderListApi =async (): Promise<ApiResponse> => {
  try {
      const response = await axios.get("/transport/provider/list");
      return response?.data;
  } catch (error: any) {
      throw error;
  }
};

// OTP verify API call
const authPostOtp = async (userData: any): Promise<ApiResponse> => {
  try {
    const response: ApiResponse = (await axios.post("verifyOTP", userData)).data;
    localStorage.setItem("loginDetails", JSON.stringify(response?.data));
    return response;

  } catch (error: any) {
    throw error;
  }
};

// Resend OTP API call
const resendPostOtp = async (userData: any): Promise<ApiResponse> => {
  try {
    return (await axios.post("resendOtp", userData)).data;
  } catch (error: any) {
    throw error;
  }
};

// Logout API call
const logOutApi = async (userToken: { headers: { Authorization: string } }): Promise<ApiResponse> => {
  try {
    return (await axios.get("logout", userToken)).data;
  } catch (error: any) {
    throw error;
  }
};


const authService = {
  authLoginPost,
  authPostOtp,
  resendPostOtp,
  logOutApi,
  authSignUp,
  transportProviderListApi
};

export default authService;
