// CKEditorComponent.js
import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import styles


const CKEditorComponent = ({ value, onChange }: any) => {
  return (
    <ReactQuill
      value={value} onChange={(e) => {
        onChange(e)
      }} 
    />
  );
};

export default CKEditorComponent;
